import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

const config = {
    apiKey: "AIzaSyCfv9RwWVvqaRGx3RUAIwKCn24snk1AdMk",
    authDomain: "jpd-ll.firebaseapp.com",
    projectId: "jpd-ll",
    storageBucket: "jpd-ll.appspot.com",
    messagingSenderId: "597090579625",
    appId: "1:597090579625:web:e6682fef8e195a29c425b8"
}
firebase.initializeApp(config);
const storage = firebase.storage();
export {storage};